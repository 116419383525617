import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

export function locationMap(domId) {
  let mapElm = document.getElementById(domId);
  if (mapElm == null) {
    return;
  }
  let params = JSON.parse(mapElm.getAttribute('data-map-params'));
  if (params == null) {
    console.warn('missing map params');
    return;
  }

  let locationMap = L.map(mapElm).setView(params.center, params.zoom || 13);
  let url = "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";

  L.tileLayer(url, {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox/streets-v11',
    accessToken: 'pk.eyJ1Ijoia3NvdXRod29ydGgiLCJhIjoiY2swMnM4bTNsMnhzMTNqcW8xNXdiaWMyMSJ9.-qqhZCbIdbAq7mGpqbPllQ'
  }).addTo(locationMap);
  if (params.marker) {
    let marker = L.marker(params.marker).addTo(locationMap);
  }
  if (params.circle) {
    let circle = L.circle(params.circle.center, {
      color: 'red',
      weight: 2,
      fillColor: '#f03',
      fillOpacity: 0.2,
      radius: parseFloat(params.circle.radius) * 1609.34 // 1 mile = 1609.34 meters
    }).addTo(locationMap);
  }
}