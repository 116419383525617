import { locationMap } from '~/../locationMap.js.erb'
import { select2 } from '~/../init-select2'

function machineSelect2() {
  const $address_line1 = $('#territory_protection_address_line1').first()
  const $address_line2 = $('#territory_protection_address_line2').first()
  const $address_city = $('#territory_protection_address_city').first()
  const $address_state_code = $('#territory_protection_address_state_code').first()
  const $address_zip_code = $('#territory_protection_address_zip_code').first()
  $("select[data-change-trigger][data-subject='machines']").each(function() {
    let nativeSelect = $(this)
    let triggerElm = $(nativeSelect.attr('data-change-trigger')).first()
    if (nativeSelect && triggerElm) {
      // console.log('init-ing select2 on element:'); console.log(targetElm);
      let machineSelect = select2(nativeSelect, {
        minimumResultsForSearch: -1,
        // minimumInputLength: 3,
        allowClear: true,
        placeholder: '',
        ajax: {
          url: '/api/app/machines.json',
          dataType: 'json',
          delay: 250,
          data(params) {
            let accountId = triggerElm.val()
            return {
              name: params.term,
              accountId
            }
          },
          processResults(data) {
            return {
              results: data.map(machine => ({
                id: machine.id,
                text: machine.label,
                disabled: machine.territoryProtection,
                address: machine.address
              }))
            }
          }
        }
      })
      triggerElm.on('change', function (e) {
        machineSelect.val(null).trigger('change')
      })
      machineSelect.on('select2:select', function(e, x, y) {
        console.log(e.params.data)
        if(e.params.data.address) {
          let attrs = e.params.data.address;
          $address_line1.val(attrs['line1']);
          $address_line2.val(attrs['line2']);
          $address_city.val(attrs['city']);
          $address_state_code.val(attrs['stateCode']).trigger('change');
          $address_zip_code.val(attrs['zipCode']);
        }
      })
    }
  })
}
$(function() {
  if ($('body.territory-protections').length == 0) {
    return;
  }
  machineSelect2();
  locationMap('locationMap');
})