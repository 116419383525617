$(function() {
  const cardPaymentSection = $('#card-payment-section');
  if (cardPaymentSection == null) {
    return;
  }

  const inputNamePrefix = cardPaymentSection.attr('data-form-object-name') || '';
  const paymentMethodRadios = cardPaymentSection.find(":radio");
  const newCardOption = paymentMethodRadios.filter("[value='new']").first();
  const newCardSection = cardPaymentSection.find('#new-card-section');
  const form = cardPaymentSection.closest('form').first();
  const submitBtn = form.find('input[type="submit"]');

  const onPaymentMethodClick = (e) => {
    if (e.target.value == 'new') {
      newCardSection.removeClass('d-none')
      mountStripeCardElement()
    } else {
      newCardSection.addClass('d-none')
      unmountStripeCardElement()
    }
  }
  paymentMethodRadios.on('click', onPaymentMethodClick);

  const cardElement = document.getElementById('stripe-card-element');
  const errorElement = document.getElementById('stripe-card-errors');
  const stripe = Stripe(window.stripe_public_key);
  const stripeElements = stripe.elements();
  if ((cardElement == null) || (errorElement == null)) {
    return;
  }
  let stripeCardToken = null;

  const card = stripeElements.create('card', {
    hidePostalCode: true,
    classes: {
      complete: 'is-valid'
    }
  });
  card.addEventListener('change', ({error}) => {
    displayStripeError(error);
  });

  const displayStripeError = (error) => {
    if (error) {
      errorElement.textContent = error.message;
    } else {
      errorElement.textContent = '';
    }
    submitBtn.attr('disabled', null)
  }

  const submitFormWithStripeToken = (token) => {
    stripeCardToken = token;
    // Insert the token ID into the form so it gets submitted to the server
    // const form = document.getElementById('payment-form');
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    if (inputNamePrefix.length > 0) {
      hiddenInput.setAttribute('name', inputNamePrefix + '[stripe_token]');
    } else {
      hiddenInput.setAttribute('name', 'stripe_token');
    }
    hiddenInput.setAttribute('value', token.id);
    form.append(hiddenInput);

    // Submit the form
    form.submit();
  }

  async function stripeFormSubmitHandler(event) {
    console.log('stripeFormSubmitHAndler');
    console.log('window.ihaPortal.stripeCardMounted = ' + window.ihaPortal.stripeCardMounted);
    if (stripeCardToken && stripeCardToken.id) {
      return true;
    }
    event.preventDefault();
    const { token, error } = await stripe.createToken(card);
    if (error) {
      stripeCardToken = null;
      displayStripeError(error)
    } else if (token) {
      submitFormWithStripeToken(token);
    }
  }

  const mountStripeCardElement = () => {
    if (window.ihaPortal.stripeCardMounted === true) {
      return;
    }
    card.mount(`#${cardElement.id}`)
    form.on('submit', stripeFormSubmitHandler)
    window.ihaPortal.stripeCardMounted = true;
  }

  const unmountStripeCardElement = () => {
    form.off('submit', stripeFormSubmitHandler)
    card.unmount()
    displayStripeError('')
    window.ihaPortal.stripeCardMounted = false;
  }

  // trigger click on the first selected radio (to fire Stripe Card JS logic)
  let checkedPaymentOption = cardPaymentSection.find(":radio:checked")
  if (checkedPaymentOption.length == 1) {
    checkedPaymentOption.click()
  }

  window.ihaPortal.mountStripeCardElement = mountStripeCardElement;
  window.ihaPortal.unmountStripeCardElement = unmountStripeCardElement;
})