import {
  init
} from './init'
const ClassicEditor = require('ckeditor5-custom-build');

function initCkEditor(e, inserted = null) {
  $(inserted ? inserted : document).find('.ck-textarea').each(function () {
    ClassicEditor.create(this, {
      removePlugins: ["Markdown"],
      toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote", "insertTable", "undo", "redo", "uploadImage", "toggleImageCaption", "imageTextAlternative"]
      ,image: {
        toolbar: [
          "imageStyle:alignLeft",
          "imageStyle:alignCenter",
          "imageStyle:alignRight",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
        ],
        styles: ["alignLeft", "alignCenter", "alignRight"],
        upload: {
          types: ["jpeg", "png"],
        },
      }
    })
  })
}

$(document).ready(function () {
  init(initCkEditor)
});