import { init } from './init'
import 'select2'

export function select2(element, options = {}) {
  options.minimumResultsForSearch = options.minimumResultsForSearch || 10;
  let tags = element.data('tags') || false
  if (tags) {
    options.tokenSeparators = [',']
    options.minimumResultsForSearch = null;
  }
  return element.select2({
    theme: 'bootstrap4',
    ...options
  })
}

function buildSelect2(e, inserted = null) {
  $(inserted ? inserted : document).find('select').not('.native').each(function() {
    select2($(this))
  })
}

export default function initSelect2() { init(buildSelect2) }
